<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="直播课程名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:7rem">直播课程名称:</span>
          <el-input
            v-model="liveCourseName"
            type="text"
            size="small"
            placeholder="请输入直播课程名称"
            clearable
          />
        </div>
         <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
          @sort-change="changeSort"
          :default-sort="{ prop: 'auditTime', order: 'ascending' }"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
            width="100px"
          />
          <el-table-column
            label="直播名称"
            align="left"
            show-overflow-tooltip
            prop="liveCourseName"
            width="300"
            fixed
          />
          <el-table-column
            label="直播课数量"
            align="left"
            show-overflow-tooltip
            prop="lessonNum"
            width="100"
          />
          <el-table-column
            label="关联班级"
            align="left"
            show-overflow-tooltip
            prop="liveProjectName"
            width="300"
          ><template slot-scope="scope">{{
              scope.row.liveProjectName || "--"
            }}</template>
          </el-table-column>  
          <el-table-column
            label="开始时间"
            align="left"
            show-overflow-tooltip
            prop="startTime"
            width="150"
          />
          <el-table-column
            label="结束时间"
            align="left"
            show-overflow-tooltip
            prop="endTime"
            width="150"
          />
          <el-table-column
            label="提交机构"
            align="left"
            show-overflow-tooltip
            prop="compName"
            width="250"
          />
          <el-table-column
            label="提交审核时间"
            align="left"
            show-overflow-tooltip
            prop="auditTime"
            width="140"
          >
            <template slot-scope="scope">{{
              scope.row.submitTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="审核人"
            align="left"
            show-overflow-tooltip
            prop="adminName"
            width="120"
            v-if="name == 'second' || name == 'three'"
          />
          <el-table-column
            label="审核时间"
            align="left"
            show-overflow-tooltip
            width="150"
            prop="auditTime"
            v-if="name == 'second' || name == 'three'"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.auditTime | moment }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150px"
            show-overflow-tooltip
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="
                  doReview(
                    scope.row.liveCourseId,
                    name,
                    scope.row.liveProjectName
                  )
                "
                >详情</el-button
              >

              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                v-show="name !== 'second'"
                @click="
                  projectPass(
                    scope.row.liveCourseId,
                    name
                  )
                "
                >通过</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                v-show=" name == 'first'"
                @click="reject(scope.row.liveCourseId, name)"
                >驳回</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      title="审核事由"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input
          type="textarea"
          placeholder="请输入审核理由"
          maxlength="100"
          show-word-limit
          v-model="auditReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "liveClassFestivalToBeReviewed",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      compId: "",
      names: "",
      liveCourseName: "",
      dialogVisible: false,
      liveCourseId: "",
      auditReason: "",
    };
  },
  computed: {},
  created() {
    this.init();
    this.getData()
  },
  methods: {
    init() {
      this.getTableHeight();
      this.getareatree();
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    changeSort(val) {
      this.auditTimeStort = val.order;
      this.getData();
    },
    getData(pageNum, url, name) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.liveCourseName) {
        params.liveCourseName = this.liveCourseName;
      }
      if (!name) {
        let routerName = this.$route.query.active;
        if (routerName == "second") {
          url = "/biz/live/course/auditPassedList";
        } else if (routerName == "three") {
          url = "/biz/live/course/auditNotPassedList";
        } else {
          url = "/biz/live/course/waitAuditList";
        }
        this.name = this.$route.query.active || "first";
      }

      this.doFetch({
        url: url,
        params,
        pageNum,
      },true,3);
    },
    doReview(liveCourseId,name,liveProjectName) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassFestivalDetails",
        query: {
          getClassInfo: JSON.stringify({
            liveCourseId,
            name:this.name,
            relationClass:liveProjectName
          }),
        },
      });
    },
    projectPass(liveCourseId,name) {
        const parmar = {
          liveCourseId,
        };
        this.$post("/biz/live/course/auditPassed", parmar,3000,true,3)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                type: "success",
                message: "审核成功",
              });

              if (name == "first") {
                this.getData(-1, "/biz/live/course/waitAuditList", name);
              } else if (name == "second") {
                this.getData(-1, "/biz/live/course/auditPassedList", name);
              } else if (name == "three") {
                this.getData(-1, "/biz/live/course/auditNotPassedList", name);
              }
            }
          })
          .catch((err) => {
            return;
          });
    },
    reject(liveCourseId, name) {
      this.liveCourseId = liveCourseId;
      this.dialogVisible = true;
      this.name = name;
    },
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        liveCourseId: this.liveCourseId,
        auditReason: this.auditReason,
      };
      this.$post("/biz/live/course/auditRefuse", parmar,3000,true,3)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.auditReason = "";
            if (this.name == "first") {
                this.getData(-1,"/biz/live/course/waitAuditList", this.name);
              } else if (this.name == "second") {
                this.getData(-1,"/biz/live/course/auditPassedList", this.name);
              } else if (this.name == "three") {
                this.getData(-1,"/biz/live/course/auditNotPassedList", this.name);
              }
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 ;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function() {},
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
